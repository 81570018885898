@import 'src/shared/styles/variables';

.tutorial-screen-container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .tutorial-screen-top-container {
    height: 100%;
    min-height: 50vh;
    @media screen and (max-width: $mui-sm) {
      min-height: 0;
      margin-bottom: 10px;
    }
  }

  .tutorial-screen-bottom-container {
    max-width: 90%;
    min-height: 50vh;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media screen and (max-width: $mui-sm) {
      justify-content: start;
    }

    .tutorial-screen-content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 20vh;
    }
  }

  @media screen and (max-width: $mui-sm) {
    .welcome-offer-desc {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .repeat-customer {
      line-height: 38px;
    }

    .buttons-bottom {
      position: fixed;
      bottom: 20px;
      width: 90%;
    }

    .not-now-button {
      padding: 0;
      height: 70px;
      line-height: 70px;
    }
  }

  @media screen and (max-width: $mui-xs) {
    .tutorial-title {
      font-size: 24px;
      line-height: 29px;
    }

    .tutorial-desc {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 24px;
    }

    .close-btn {
      font-size: 14px !important;
      bottom: 0;
      padding: 10px 0 !important;
    }
  }

  @media screen and (min-width: $mui-md) {
    .close-btn {
      margin-top: -50px;
    }
  }
}
