@import 'src/shared/styles/variables';

.offer-type-container {
    height: 100px;

    .offer-type-inner-container {
        position: absolute;
        margin-top: 10%;
        border-radius: 25px;
        width: 100%;

        .offer-type-wrapper {
            max-width: 90%;
            width: 100%;
            margin: 0 auto;
            display: block;
            padding-top: 12px;

            @media screen and (max-width: $mui-sm) {
                padding-bottom: 0;

                .section-title {
                    line-height: 32px;
                    margin-bottom: 15px;
                }

                .section-subtitle {
                    line-height: 31px;
                    margin-bottom: 45px;
                    font-size: 20px;
                }
            }

            @media screen and (max-width: $mui-xs) {
                .section-title {
                    font-size: 20px;
                    line-height: 24px;
                }

                .section-subtitle {
                    font-size: 13px;
                    line-height: 20px;
                    margin-bottom: 32px;
                }
            }
        }
    }
}
