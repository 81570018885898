@import 'src/shared/styles/variables';

.offer-type-container {
    height: 100px;

    .offer-type-inner-container {
        position: absolute;
        margin-top: 10%;
        height: 90%;
        border-radius: 25px;

        .offer-type-wrapper {
            max-width: 90%;
            width: 100%;
            margin: 0 auto;
            display: block;
            padding: 50px 0px;

            @media (max-width: $mui-md) {
                padding: 24px 0 0 0;
            }
        }

        .promotion-input-form-control {
            width: 100%;

            .promotion-input-container {
                width: 100%;
                height: 260px;
                border-radius: 17.1429px;
                text-align: center;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                position: relative;

                .promotion-validation {
                    position: absolute;
                    bottom: 10px;
                    color: #ff604f;

                    .error-message {
                        @media screen and (max-width: $mui-xs) {
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                }

                @media screen and (max-width: $mui-xs) {
                    height: 130px;
                }
            }

            .MuiInput-input,
            .promotion-suffix {
                font-family: Plex-Light;
                font-style: normal;
                font-weight: 500;
                font-size: 60px;
                line-height: 93px;
                position: relative;
                text-align: center;

                @media screen and (max-width: $mui-xs) {
                    font-size: 28px;
                    line-height: 42px;
                }
            }

            .input-with-suffix .MuiInput-input {
                width: 106px;
                text-align: right;
                padding-right: 10px;

                @media screen and (max-width: $mui-xs) {
                    width: 50px;
                    padding-right: 4px;
                }
            }

            .promotion-suffix {
                width: auto;
                line-height: 72px;

                @media screen and (max-width: $mui-xs) {
                    line-height: 32px;
                }
            }

            .gift-promotion-suffix {
                min-width: 40%;
                max-width: 90%;
                border-bottom: 5px solid #b0b7c3;

                @media screen and (max-width: $mui-xs) {
                    border-bottom-width: 2.5px;
                }
            }

            .validate-action-container {
                transform: translateX(-50%);
                position: relative;
                bottom: 0;
                margin-bottom: 5%;
                left: 50%;
                width: 100%;
            }
        }

        .promotion-title {
            line-height: 32px;

            @media screen and (max-width: $mui-md) {
                margin-bottom: 15px;
            }

            @media screen and (max-width: $mui-xs) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .promotion-subtitle {
            line-height: 31px;

            @media screen and (max-width: $mui-xs) {
                font-size: 13px;
                line-height: 19px;
            }
        }

        .promotion-form-subtitle {
            @media screen and (max-width: $mui-md) {
                margin-bottom: 40px;
            }

            @media screen and (max-width: $mui-xs) {
                font-size: 13px;
                line-height: 19px;
            }
        }
    }
}
