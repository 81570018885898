:root {
    --toastify-color-info: rgba(213, 233, 235, 0.9);
    --toastify-color-success: rgba(124, 220, 179, 0.9);
    --toastify-color-warning: rgba(255, 194, 102, 0.9);
    --toastify-color-error: rgba(255, 132, 102, 0.9);

    --toastify-text-color-info: rgb(0, 0, 0);
    --toastify-text-color-success: rgb(255, 255, 255);
    --toastify-text-color-warning: rgb(255, 255, 255);
    --toastify-text-color-error: rgb(255, 255, 255);

    .Toastify__toast-container {
        width: 100%;
        padding: 0%;
    }

    .Toastify__toast-container--top-center {
        top: 0%;
    }

    .Toastify__toast {
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.8);
        font-size: 1.2rem;
    }

    .Toastify__toast-icon {
        width: 1.2em;
    }
}
