@import 'src/shared/styles/variables';



.activation-screen-container {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .activation-screen-top-container {
        height: 100%;
        min-height: 50vh;
        @media screen and (max-width: $mui-sm) {
            min-height: 0;
            margin-bottom: 10px;
        }
    }

    .activation-screen-bottom-container {
        max-width: 90%;
        min-height: 50vh;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @media screen and (max-width: $mui-sm) {
            justify-content: start;
        }

        .activation-screen-content-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 20vh;
        }
    }

    @media screen and (max-width: $mui-xs) {
        .welcome-offer-desc {
            margin-top: 10px;
            margin-bottom: 100px;
        }

        .activation-screen-bottom-container {
            margin-top: 30px;
        }

        .repeat-customer {
            line-height: 28px;
        }

        .buttons-bottom {
            position: relative;
            bottom: 0;
            width: 100%;
        }

        .not-now-button {
            padding: 0;
            height: 70px;
            line-height: 70px;
        }
    }

    @media screen and (max-width: $mui-xs) {
        .repeat-customer {
            font-size: 24px;
            line-height: 29px;
        }

        .welcome-offer-desc {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 24px;
        }

        .not-now-button {
            height: 50px;
            line-height: 50px;
        }

        .activation-screen-bottom-container {
            margin-top: 0px;
        }

        .not-now-button > .MuiTypography-button {
            font-size: 14px;
            line-height: 52px;
        }

    }

    @media screen and (max-width: $mui-md) {
        .not-now-button {
            padding-bottom: 0;
        }
    }
}
