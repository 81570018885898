@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/old-fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/old-fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/old-fonts/poppins-v15-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/old-fonts/poppins-v15-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/old-fonts/poppins-v15-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/old-fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
@font-face {
    // TODO: Add gilroy font in .woff2 and .svg file
    font-family: 'Gilroy';
    src: url('../fonts/old-fonts/gilroy-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/old-fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'),
        //   /* IE6-IE8 */ url('./fonts/gilroy-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/old-fonts/gilroy-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/old-fonts/gilroy-regular.ttf') format('truetype');
    // /* Safari, Android, iOS */ url('./fonts/gilroy-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Gilroy-Medium';
    src: 
        /* Modern Browsers */ url('../fonts/old-fonts/gilroy-medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-Thin';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-ExtraLight';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-Light';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-Regular';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-Medium';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-SemiBold';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Plex-Bold';
    src:
            /* Modern Browsers */ url('../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Radial-SemiBold';
    src:
            /* Modern Browsers */ url('../fonts/Radial/Radial-SemiBold.ttf') format('truetype');
}
